@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Inter&family=KoHo&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter&family=KoHo&family=Lora:ital,wght@0,400..700;1,400..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter&family=KoHo&family=Lora:ital,wght@0,400..700;1,400..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter&family=KoHo&family=Lora:ital,wght@0,400..700;1,400..700&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Lora", "inter", "Poppins",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.getInTouch {
  box-shadow: 11px 9px 12px 0px #ffbb6bad;
  background: #ffbb6b;
  border-radius: 35px;
}
.text-clamp {
  font-size: clamp(1rem, 24px, 3rem);
}
.linerarBg {
  background: linear-gradient(
    145.89deg,
    #30519478 -0.93%,
    #ddc4a793 20.52%,
    #d6a8745a 74.66%,
    #2142844e 100%
  );
  box-shadow: 11px 9px 12px 0px #ffd5a584;

  border-radius: 60px;
  /* opacity: 20%; */
  --tw-bg-opacity: 20%;
}
.linerarBgsd {
  background: linear-gradient(
    145.89deg,
    #30519478 -0.93%,
    #ddc4a793 20.52%,
    #d6a8745a 74.66%,
    #2142844e 100%
  );
  box-shadow: 11px 9px 12px 0px #ffd5a584;

  border-radius: 60px;
}
.glassbg {
  background: linear-gradient(
    145.89deg,
    #21428449 -0.93%,
    #ffba6b3f 20.52%,
    #ffba6b30 74.66%,
    #214284 100%
  );
  border: 2px solid #ffffff49;
  border-radius: 60px;
}
.glassbg2 {
  background: linear-gradient(
    145.89deg,
    #21428449 -0.93%,
    #ffba6b3f 20.52%,
    #ffba6b30 74.66%,
    #2142844d 100%
  );
}
.boxShadow {
  box-shadow: 11px 9px 12px 0px #ffbb6bad;
}
.rotate4 {
  --tw-rotate: -5deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.active-link {
  background-color: blueviolet;
}
.darkbg {
  background: #ffbb6b4d;
  border-bottom: 1px solid #ffbb6b;
  border-radius: 47px;
  padding: 10px 50px 10px 50px;
}
.circular-carousel-slider {
  position: absolute;
  left: 41%;
  /* top: calc((100vh - 400px) / 2); */

  transform: translate(-50%, -50%);
  top: 350px;
  transform-origin: 150px 2500px;
}

.slide-item {
  position: absolute;
  width: 280px;
  /* background: violet; */
  /* height: 400px; */
  transform-origin: 150px 2500px;
  border-radius: 5px;
  cursor: grab;
  transition: transform 0.5s ease; /* Add smooth transition */
}

.lightboxShadow {
  box-shadow: 11px 9px 12px 0px #ffbb6b42;
}
.trapz1 {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: #fdf3da;
}

.trapz1:after {
  position: absolute;
  top: -162px;
  content: "";
  height: 78px;
  width: 100%;
  border-right: 1524px solid #fdf3da;
  border-top: 163px solid transparent;
}
.trapz3 {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: #cb9200;
}

.trapz3::before {
  position: absolute;
  top: -162px;
  content: "";
  height: 78px;
  width: 100%;
  border-right: 1524px solid #e9aa0c;
  border-top: 163px solid transparent;
}
.trapz2 {
  position: relative;
  height: 100%;

  width: 100%;
  background-color: #214284;
  border-bottom-left-radius: 60px;
  border-bottom-right-radius: 60px;
  /* padding: 60px; */
  /* left: 100px; */
  /* top: 300px; */
}
.trapz2:after {
  position: absolute;
  top: -76px;
  content: "";
  height: 78px;
  width: 100%;
  border-right: 1524px solid #214284;
  border-top: 76px solid transparent;
}
.profileGrad {
  background: linear-gradient(
    315deg,
    #ff8e4f 0%,
    #ff9153 0.01%,
    #ffdf8b 92.19%
  );
}
.dropdownShadow {
  box-shadow: 11px 9px 12px 0px #ffbb6bad;
}
