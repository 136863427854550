/* Add your existing CSS styles here */

/* Apply rounded corners to the first row of the table */
table th:first-child {
 border-top-left-radius: 40px;
 border-bottom-left-radius: 40px;
}

table th:last-child {
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
}

/* Optional: Adjust padding for better readability */
table th,
table td {
  padding: 10px;
}

.animate-scroll {
  /* display: inline-block; */
  white-space: nowrap;
  animation: scroll 20s linear infinite;
}

@keyframes scroll {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-50%);
  }
}
.panel {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 4rem;
  color: white;
  font-weight: bold;
}
